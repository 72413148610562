import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import './index.css';
import PubliSmart from './pages/PubliSmart';
import rootReducer from './redux/reducers';
import rootSaga from './redux/sagas';
import * as serviceWorker from './serviceWorker';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [];
middlewares.push(sagaMiddleware);

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middlewares.push(logger);
}

const store = createStore(
    rootReducer,
    applyMiddleware(...middlewares),
 );

 sagaMiddleware.run(rootSaga);

 const container = document.getElementById('publismart');
 const root = createRoot(container);

 root.render(
    <Provider store={store}>
            <PubliSmart />
    </Provider>
 );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
