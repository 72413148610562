import dayjs from "dayjs";
import {getApiUrl} from "../../config/helper";
import {
    AFFICHES_RECEIVED,
    AGENDA_RECEIVED,
    BUTTONS_RECEIVED,
    FETCH_ERROR,
    MOBILITE_RECEIVED,
    NEWS_RECEIVED,
    SET_SELECTED_EVENT
} from "../actions";

const defaultState = {
    affiches: [],
    buttons: [],
};

const smallestCommons = (arr) => {
    let max = Math.max(...arr);
    let array = arr;

    // Creating the function that .every will operate on
    const lowestCommon = (currentValue) => n % currentValue === 0;
    let common = false;
    let n = max * (max - 1);
    if (max === 1) n = 1;


    // Checking whether the first value for n is the lowestCommon Multiple
    common = array.every(lowestCommon);

    // Checking for a true result from the array
    while (common === false) {
        n++;
        common = array.every(lowestCommon);
    }
    return n;
};

const Data = (state = defaultState, action, customer) => {
    switch (action.type) {
        case AFFICHES_RECEIVED:

            if (customer === 'publismart') {
                return { ...state, affiches: action.affiches };
            }

            const apiUrl = getApiUrl();
            let clients = action.affiches;
            let affiches = [];

            //console.log("Affiches Before", clients);
            //console.log("Affiches STATE", customer);

            // Cleanup clients first
            // remove clients that have no video or start and end time are not ok

            let videoCounts = []; // Array with video counts for each client

            for (let i = clients.length - 1; i >= 0; i--) {
                const client = clients[i];
                let hasVideo = false;
                let inTime = false;
                let clientVideoCount = 0;
                let videoCount = 0;

                for (const prop in client) {
                    if (prop.startsWith("video_")) videoCount++;
                }

                for (let j = 1; j <= videoCount; j++) {
                    const video = client["video_" + j];
                    if (video !== null && video !== "") {
                        hasVideo = true;
                        clientVideoCount++;

                        if (video.indexOf(`${apiUrl}storage/`) === -1)
                            client["video_" + j] = `${apiUrl}storage/${video}`;

                    } else {
                        delete client["video_" + j];
                    }
                }

                let start = dayjs(client["start_date"]);
                let end = dayjs(client["end_date"]);
                let now = dayjs();

                if (now > start && now < end) inTime = true;

                if (hasVideo === false || inTime === false || client[customer] !== true ) clients.splice(i, 1);
                if (inTime === true) {
                    videoCounts.push(clientVideoCount);
                    client["videoCount"] = clientVideoCount;
                }
            }

            //console.log("Affiches After", clients);

            // How many rounds do we need to get an even playlist ?
            let maxRounds = smallestCommons(videoCounts);

            //console.log("Affiches VideoCounts", videoCounts);
            //console.log("Affiches MaxRounds", maxRounds);

            // Setup a Video Playlist
            clients.forEach((client) => {
                let videos = [];

                // Add videos per client
                for (let i = 1; i <= client.videoCount; i++) {
                    videos.push(client["video_" + i]);
                }

                // How many times do we need to duplicate the list
                let count = maxRounds / videos.length;
                let clientPlaylist = [];

                // Duplicate the playlist
                for (let i = 0; i < count; i++) {
                    clientPlaylist.push(...videos);
                }

                affiches.push(clientPlaylist);
            });

            //console.log("affiches", affiches);

            if (JSON.stringify(state.affiches) !== JSON.stringify(affiches)) {
                //console.log("AFFICHES", state.affiches);
                //console.log("AFFICHES", affiches);
                return { ...state, affiches: affiches, maxRounds: maxRounds };
            } else return state;

        case BUTTONS_RECEIVED:
            let buttons = [];

            for (let i = 1; i < 13; i++) {
                if (action.buttons[0]["image_" + i])
                    buttons.push(action.buttons[0]["image_" + i]);
            }

            if (JSON.stringify(state.buttons) !== JSON.stringify(buttons))
                return { ...state, buttons: buttons };
            else return state;

        case NEWS_RECEIVED:
            return { ...state, news: action.news };

        case MOBILITE_RECEIVED:
            return { ...state, mobilite: action.mobilite };

        case AGENDA_RECEIVED:
            return { ...state, agenda: action.agenda };

        case SET_SELECTED_EVENT:
            return { ...state, selectedEvent: action.event };

        case FETCH_ERROR:
            console.log("FETCH ERROR: SHOW ERROR PAGE : " + action.error);
            return state;

        default:
            return state;
    }
};

export default Data;
