import { put, takeLatest, select } from 'redux-saga/effects';
import { getSApiUrl } from '../../config/helper';
import { getCustomer, getScreen, getLocal } from '../../selectors';

import { 
    SET_CUSTOMER,
    CONFIG_RECEIVED,
    SET_ERROR,
    pollStart
} from '../actions';

function* fetchConfig() {
    const customer = yield select(getCustomer); 
    const screen = yield select(getScreen); 
    const local = yield select(getLocal); 
    const apiUrl = getSApiUrl();

    if (!customer) yield put({ type: SET_ERROR, error: 2, message: 'CUSTOMER not set!' });
    if (!screen) yield put({ type: SET_ERROR, error: 3, message: 'SCREEN not set!' });

    let screenUrl = `/config/${customer}_screen1.json`;

    try {
        if (local === "1") {
            const uuid = yield fetch('http://localhost:8000/identity')
            .then(response => response.json()); 

            const location = yield fetch(`${apiUrl}/playerinfo?player=${uuid.identity}`)
            .then(response => response.json()); 

            const navigation = yield fetch(`${apiUrl}/preset?customer=${location.player.client}&screen=${screen}`)
            .then(response => response.json()); 

            const json = {
                location: location.player.location,
                navigation: navigation.navigation
            }

            yield put({ type: CONFIG_RECEIVED, json: json });   
            yield put(pollStart());
            
        } else {
            const json = yield fetch(screenUrl)
            .then(response => response.json()); 
            yield put({ type: CONFIG_RECEIVED, json: json });
            yield put(pollStart());
        }

        document.title = 'Smart Touch';

    } catch (error) {
        document.title = 'Smart Touch error';
        yield put({ type: SET_ERROR, error: 1, message: 'CUSTOMER/SCREEN does not exist!' });
    }
    
}

function* fetchConfigWatcher() {
    yield takeLatest(SET_CUSTOMER, fetchConfig)
}

export default fetchConfigWatcher;
