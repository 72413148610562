import dayjs from 'dayjs';
import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import Buttons from '../components/Buttons';
import images from '../config/images';

const Container = styled.div`
    width: ${props => props.theme.scale * 918}px;
    height: 100%;
    position: relative;
`;

const ScrollContainer = styled.div`
    ${(props) => props.theme.customer === 'publismart' ? `height: 100%;` : `height: ${props.theme.scale * 1224}px; `}
    width: 100%;
    overflow-y: scroll;
`;

const List = styled.div`
    display: table;
    width: 100%;
    > div:nth-child(even) {
        background-color: #FAFAFA;
    }
`

const Line = styled.div`
    display: table-row;
    width: 100%;
`;

const Bus = styled.div`
    font-size: ${props => props.theme.scale * 32}px;
    font-family: 'MuseoSans-900';
    text-align: left;
    color: ${props => props.theme.black};
    display: table-cell;
    vertical-align: middle;
    height: ${props => props.theme.scale * 98}px;
    padding: 0 ${props => props.theme.scale * 20}px;
`;

const Direction = styled.div`
    font-size: ${props => props.theme.scale * 24}px;
    font-family: 'MuseoSans-300';
    color: ${props => props.theme.black};
    display: table-cell;
    vertical-align: middle;
    height: ${props => props.theme.scale * 98}px;
`;

const Planned = styled.div`
    font-size: ${props => props.theme.scale * 32}px;
    font-family: 'MuseoSans-900';
    text-align: center;
    color: ${props => props.theme.black};
    display: table-cell;
    vertical-align: middle;
    height: ${props => props.theme.scale * 98}px;
`;

/*
const Expected = styled.div`
    font-size: ${props => props.theme.scale * 32}px;
    font-family: 'MuseoSans-900';
    text-align: center;
    color: ${props => props.theme.shade1};
    display: table-cell;
    vertical-align: middle;
    height: ${props => props.theme.scale * 98}px;
`;
*/

const ListHead = styled.div`
    display: table-row;
    background: black;
    width: 100%;
`;

const BusHead = styled.div`
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-300';
    text-align: left;
    color: ${props => props.theme.white};
    display: table-cell;
    vertical-align: middle;
    height: ${props => props.theme.scale * 98}px;
    text-transform: uppercase;
    width: ${props => props.theme.scale * 100}px;
    padding: 0 ${props => props.theme.scale * 20}px;
`;

const DirectionHead = styled.div`
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-300';
    color: ${props => props.theme.white};
    display: table-cell;
    vertical-align: middle;
    height: ${props => props.theme.scale * 98}px;
    text-transform: uppercase;
    width: ${props => props.theme.scale * 588}px;
`;

const PlannedHead = styled.div`
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-300';
    text-align: center;
    color: ${props => props.theme.white};
    display: table-cell;
    vertical-align: middle;
    height: ${props => props.theme.scale * 98}px;
    text-transform: uppercase;
`;

const ExpectedHead = styled.div`
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-300';
    text-align: center;
    color: ${props => props.theme.white};
    display: table-cell;
    vertical-align: middle;
    height: ${props => props.theme.scale * 98}px;
    text-transform: uppercase;
`;

const Error = styled.div`
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    top: ${props => props.theme.scale * 130}px;
    left: ${props => props.theme.scale * 0}px;
    padding-left: ${props => props.theme.scale * 20}px;
`;

const ErrorTitle = styled.div`
    font-size: ${props => props.theme.scale * 26}px;
    font-family: 'MuseoSans-900';
`;

const ErrorUrl = styled.div`
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-300';
`;

const Placeholder = styled.img`
    position: absolute;
    top: ${props => props.theme.scale * 0}px;
    right: ${props => props.theme.scale * 40}px;
    width: ${props => props.theme.scale * 200}px;
`;

const ErrorContent = () => {
    return (
        <Container>
            <ScrollContainer>
                <List>
                    <ListHead>
                        <BusHead>Ligne</BusHead>
                        <DirectionHead>Destination</DirectionHead>
                        <PlannedHead>Départ</PlannedHead>
                        <ExpectedHead>Temps-réel</ExpectedHead>
                    </ListHead>
                    <Error>
                        <ErrorTitle>Donnée momentanement indisponible</ErrorTitle>
                        <ErrorUrl>mobilitéit.lu</ErrorUrl>
                        <Placeholder src={images.logos.verkeiersverbond} />
                    </Error>
                </List>
            </ScrollContainer>
            <Buttons type="small" />
        </Container>
    );
}

const MobiliteContent = ({ mobilite }) => {
    const customer = useSelector(state => state.config.customer);
    if (!mobilite) return(null);

    let fDepartures = [];
    if (mobilite.departures.length > 0) {
        
        fDepartures = mobilite.departures.filter((departure) => {
            let cur = dayjs(`${departure.date} ${departure.time}`);
            if (cur.diff(dayjs(),'minute', true) > 0) {
                return true;
            }  else return false;
        });

        //(fDepartures);
    }


    return (
        <Container>
            <ScrollContainer>
                <List>
                    <ListHead>
                        <BusHead>Ligne</BusHead>
                        <DirectionHead>Destination</DirectionHead>
                        <PlannedHead>Départ</PlannedHead>
                    </ListHead>
                    {fDepartures.length > 0 ? <React.Fragment>
                        {fDepartures.map((item,i) => <Line key={i}>
                            <Bus>{item.name.replace('Bus ', '')}</Bus>
                            <Direction>{item.direction}</Direction>
                            <Planned>{dayjs(`${item.date} ${item.time}`).format('HH:mm')}</Planned>
                        </Line>)}    
                    </React.Fragment>
                    : 
                    <Error>
                        <ErrorTitle>Donnée momentanement indisponible</ErrorTitle>
                        <ErrorUrl>mobilitéit.lu</ErrorUrl>
                        <Placeholder src={images.logos.verkeiersverbond} />
                    </Error>}
                </List>
            </ScrollContainer>
            {customer !== 'publismart' && <Buttons type="small" />}
        </Container>
    );
}

const Mobilite = () => {
    const mobilite = useSelector(state => state.data.mobilite);

    if (!mobilite) return <ErrorContent />;
    return <MobiliteContent mobilite={mobilite} />
    
}

export default Mobilite;