export const getApiUrl = (customer) => {
    const myStorage = window.localStorage;
    const local = myStorage.getItem('local');

    if (local === '1') {
        const original = "https://sm4rt.online/";
        let localUrl = 'http://localhost:8000/proxy/';

        if (original.substr(0, 8) === 'https://') {
            localUrl += original.substr(8);
        } else if (original.substr(0, 7) === 'http://') {
            localUrl += original.substr(7);
        } else {
            localUrl += original;
        }

        return localUrl;
    }

    return "https://sm4rt.online/";
}

// Remove when api calls are final
export const getSApiUrl = () => {
    const myStorage = window.localStorage;
    const local = myStorage.getItem('local');

    if (local === '1') {
        const original = process.env.REACT_APP_API_URL;
        let localUrl = 'http://localhost:8000/proxy/';

        if (original.substr(0, 8) === 'https://') {
            localUrl += original.substr(8);
        } else if (original.substr(0, 7) === 'http://') {
            localUrl += original.substr(7);
        } else {
            localUrl += original;
        }

        return localUrl;
    }

    return process.env.REACT_APP_API_URL;
}

/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
export const shuffle = (a) => {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}