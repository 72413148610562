import dayjs from 'dayjs';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import Buttons from '../components/Buttons';
import {Grid} from '../components/Layout';
import SwipeIndicator from '../components/SwipeIndicator';

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const ScrollContainer = styled.div`
    ${(props) => props.theme.customer === 'publismart' ? `height: 100%;` : `height: ${props.theme.scale * 1224}px; `}
    width: 100%;
`;

const Event = styled.div`
    flex: 0 0 50%;
    height: ${props => props.theme.scale * 410}px;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;

    * {
        box-sizing: border-box;
    }
`;

const EventImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: ${props => props.theme.black};
    text-align: center;
    flex: 0 0 auto;
`;

const EventImage = styled.img`
    height: ${props => props.theme.scale * 410}px;
    width: 100%;
    object-fit: cover;
`;

const EventContent = styled.div`
    padding: ${props => props.theme.scale * 15}px;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    background-color: rgba(255,255,255,0.8);
`;

const EventType = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    font-family: 'MuseoSans-300';
    margin-bottom: ${props => props.theme.scale * 3}px;
    text-transform: uppercase;
`;

const EventTitle = styled.div`
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-900';
    margin-bottom: ${props => props.theme.scale * 15}px;
`;

const EventVenue = styled.div`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-300';
    margin-bottom: ${props => props.theme.scale * 5}px;
`;

const EventDate = styled.div`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-900';
`;

const EventHour = styled.span`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-300';
`;

const SwipeIndicatorWrapper = styled(SwipeIndicator)`
    position: absolute;
    top: ${props => props.theme.scale * 700}px;
    left: ${props => props.theme.scale * 420}px;
`;

const Agenda = () => {
    const agenda = useSelector(state => state.data.agenda);
    const customer = useSelector(state => state.config.customer);
    const [swipeIndicator, setSwipeIndicator] = useState(true);

    if (!agenda || agenda.error) return(null);

    const hideSwiper = () => { 
        console.log('SCTROLL');
        setSwipeIndicator(false);
    }

    return (
        <Container>
            <ScrollContainer onMouseDown={hideSwiper} onTouchStart={hideSwiper} onScroll={hideSwiper}>
            <Grid>
            {agenda.map((event,index) => (
                <Event key={index}>
                    <EventImageWrapper>
                        {event.imageurl && <EventImage src={event.imageurl} alt={event.title} />}
                    </EventImageWrapper>
                    <EventContent>
                        <EventType>{event.type}</EventType>                        
                        <EventTitle>{event.title}</EventTitle>
                        <EventVenue>{event.venue.name}</EventVenue>
                        <EventDate>
                            {(dayjs(event.end).format('DD/MM/YYYY') === dayjs(event.start).format('DD/MM/YYYY')) && <span>{dayjs(event.start).format('DD/MM/YYYY')} <EventHour>{dayjs(event.start).format('HH:mm')} - {dayjs(event.end).format('HH:mm')}</EventHour></span>}
                            {(dayjs(event.end).format('DD/MM/YYYY') !== dayjs(event.start).format('DD/MM/YYYY')) && <span>{dayjs(event.start).format('DD/MM/YYYY')} - {dayjs(event.end).format('DD/MM/YYYY')}</span>}
                            {(!event.end && event.start) && dayjs(event.start).format('DD/MM/YYYY HH:mm')}
                        </EventDate>
                    </EventContent>
                    
                </Event>
            ))}
            </Grid> 


            </ScrollContainer>
            {customer !== 'publismart' && <Buttons type="small" />}
            {swipeIndicator && <SwipeIndicatorWrapper direction="v" />}  
        </Container>
    );
}

export default Agenda;
