import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import images from '../config/images';
import { shuffle } from '../config/helper';

const ButtonsPlaceholder = styled.div`
    width: 100%;
    height: ${props => props.theme.scale * 408}px;
`;

const Placeholder = styled.img`
    width: 100%;
    height: 100%;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const ButtonWrapper = styled.div`
    position: relative;
`;

const Button = styled.div`
    line-height: 0;

    img {
        width: 100%;
    }
`;

const Buttons = ({ type }) => {
    const buttons = useSelector(state => state.data.buttons);

    if (!buttons) return null;

    let output = [];
    if (buttons) output = buttons.slice(0,12);
    let colors = ['#f58221', '#9ccc3b', '#ffc20d', '#24809e', '#f05ca3', '#966163', '#de5926', '#0087bf', '#40ad4a', '#d46e75', '#005ca6', '#00babf'];

    if (output.length < 13) {
        let rest = 12 - output.length;
        
        for (let i = 0; i < rest; i++) {
            let rand =  Math.floor(Math.random() * Math.floor(colors.length));
            let placeholder = {
                placeholder: true,
                backgroundColor: colors[rand]
            };

            output.push(placeholder);
            colors.splice(rand,1);
            output = shuffle(output);
        }
    }

    return (
        <React.Fragment>
        {!buttons || buttons.length === 0 ? 
            <ButtonsPlaceholder>
                <Placeholder src={images.logos.publilux} />
            </ButtonsPlaceholder>
        :
            <ButtonsWrapper>
            {output.map((item, i) => {
                let width = '153px';
                let height = '204px'
                if (type === 'big') {
                    width = '306px';
                    height = '408px';
                }

                let style = {
                    backgroundColor: item.backgroundColor,
                    flex: '0 0 ' + width,
                    width: width,
                    height: height
                };
                return(
                    <ButtonWrapper key={i} style={style}>
                        <Button><img src={item} alt="" /></Button>
                    </ButtonWrapper>
                );
            })}        
            </ButtonsWrapper>
        }
        
        </React.Fragment>
    );
};

export default Buttons;