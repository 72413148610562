import {
    GoogleMap, LoadScript, Marker
} from "@react-google-maps/api";
import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import Buttons from '../components/Buttons';
import images from '../config/images';
import mapStyles from '../config/mapStyles';



const HomeContainer = styled.div`
    width: 100%;
    height: 100%;
`;

const GoogleMapWrapper = styled.div`
    width: 100%;
    ${(props) => props.theme.customer === 'publismart' ? `height: 100%;` : `height: ${props.theme.scale * 1224}px; `}
    overflow: hidden;
`;


const Plan = () => {
    const location = useSelector(state => state.config.location);
    const customer = useSelector(state => state.config.customer);
    const theme = useSelector(state => state.config.theme);

    if (!location) return(null);

    const style = {
        width: '100%',
        height: '103%'
    };

    const center = {
        lat: location.latitude,
        lng: location.longitude
    };

    const zoom = 18;
    let marker = images.map.marker;
    if (theme.marker) marker = `/images/icons/${theme.marker}`;

    const homeMarker = <Marker
        onLoad={marker => {
            //console.log('marker: ', marker)
        }}
        position={{
            lat: location.latitude,
            lng: location.longitude
        }}
        icon={{
            url: marker,
            scaledSize: { width: 50, height: 55 }
        }}
    />;


    return (
        <HomeContainer>
            <LoadScript
                googleMapsApiKey="AIzaSyC0KS2RkGwP6i9-vgSeiEwCmZ-p2Bq2rSQ"
            >
                <GoogleMapWrapper>
                    <GoogleMap
                        id='map'
                        center={center}
                        zoom={zoom}
                        mapContainerStyle={style}
                        options={{
                            disableDefaultUI: true,
                            styles: mapStyles,
                            minZoom: 14,
                            maxZoom: 0
                        }}
                    >
                        {homeMarker}
                    </GoogleMap>
                </GoogleMapWrapper>
            </LoadScript>
            {customer !== 'publismart' && <Buttons />}
        </HomeContainer>
    );
}

export default Plan;
