import themes from "../../config/themes";
import { CONFIG_RECEIVED, SET_CUSTOMER, SET_ERROR } from "../actions";

const defaultState = {
    width: 1080,
    height: 1920,
    scale: 1,
    customer: undefined,
    screen: undefined,
    theme: {},
    loading: false,
    navigation: undefined,
    location: undefined,
    error: {
        number: undefined,
        message: undefined,
    },
};

const Config = (state = defaultState, action) => {
    switch (action.type) {
        case SET_CUSTOMER:
            let themeConfig = themes[action.customer];
            themeConfig = { ...themeConfig, ...themes.global };
            return {
                ...state,
                customer: action.customer,
                local: action.local,
                screen: action.screen,
                theme: themeConfig,
                loading: true,
            };

        case CONFIG_RECEIVED:
            return {
                ...state,
                location: action.json.location,
                navigation: action.json.navigation,
            };

        case SET_ERROR:
            let error = {
                number: action.error,
                message: action.message,
            };
            return { ...state, error: error };

        default:
            return state;
    }
};

export default Config;
