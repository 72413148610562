import {useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";


const InactivityCheck = () => {
    const [isRunning, setIsRunning] = useState(false);
    let location = useLocation();
    let navigate = useNavigate();


    var inactivityTime = function () {
        let time;
        setIsRunning(true);
        window.onload = resetTimer;
        // DOM Events
        document.ontouchstart = resetTimer;
        document.ontouchmove = resetTimer;
        document.onkeypress = resetTimer;
        document.onclick = resetTimer;
        document.onmousemove = resetTimer; 
        document.onmousedown = resetTimer;
        document.ontouchmove = resetTimer; 

        var gotToHome = function () {
            let cUrl = new URL(window.location);
            if (cUrl.pathname !== location.pathname ) {
                navigate(location.pathname);
            }
        } 
    
        function resetTimer() {            
            clearTimeout(time);
            time = setTimeout(gotToHome, 8000); // 60s
        }
    };

    if (isRunning === false) {
        inactivityTime();
    }

    return(null);
}

export default InactivityCheck;
