import Config from "./config";
import Data from "./data";

const reducer = (state = null, action) => {
    const data = Data(state?.data, action, state?.config?.customer);
    const config = Config(state?.config, action);
    return data !== state?.data || config !== state?.config
        ? { config, data }
        : state;
};

export default reducer;
