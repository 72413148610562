export const SET_CUSTOMER = 'SET_CUSTOMER';
export const CONFIG_RECEIVED = 'CONFIG_RECEIVED';
export const SET_ERROR = 'SET_ERROR';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SET_LOCAL = 'SET_LOCAL';

export const POLL_START = 'POLL_START';
export const POLL_STOP = 'POLL_STOP';

export const LOAD_AFFICHES = 'LOAD_AFFICHES';
export const AFFICHES_RECEIVED = 'AFFICHES_RECEIVED';

export const LOAD_BUTTONS = 'LOAD_BUTTONS';
export const BUTTONS_RECEIVED = 'LOAD_BUTTONS_SUCCESS'

export const LOAD_NEWS = 'LOAD_NEWS';
export const NEWS_RECEIVED = 'LOAD_NEWS_SUCCESS';

export const LOAD_MOBILITE = 'LOAD_MOBILITE';
export const MOBILITE_RECEIVED = 'MOBILITE_RECEIVED';

export const LOAD_AGENDA = 'LOAD_AGENDA';
export const AGENDA_RECEIVED = 'AGENDA_RECEIVED';

export const SET_SELECTED_EVENT = 'SET_SELECTED_EVENT';

export const setCustomer = (customer, screen, local) => ({
    type: SET_CUSTOMER,
    customer: customer,
    screen: screen,
    local: local
});

export const getConfig = () => ({
    type: CONFIG_RECEIVED
});

export const setError = () => ({
    type: SET_ERROR
});
/*
export const setLocal = (local) => ({
    type: SET_LOCAL,
    local: local
});
*/
export const pollStart = () => ({
    type: POLL_START
});

export const pollStop = () => ({
    type: POLL_STOP
});

export const loadAffiches = () => ({
    type: LOAD_AFFICHES
});

export const affichesReceived = (affiches) => ({
    type: AFFICHES_RECEIVED,
    affiches: affiches
});

export const loadButtons = () => ({
    type: LOAD_BUTTONS
});

export const buttonsReceived = (buttons) => ({
    type: AFFICHES_RECEIVED,
    buttons: buttons
});

export const loadNews = () => ({
    type: LOAD_NEWS
});

export const newsReceived = (news) => ({
    type: NEWS_RECEIVED,
    news: news
});

export const loadMobilite = () => ({
    type: LOAD_MOBILITE
});

export const mobiliteReceived = (mobilite) => ({
    type: MOBILITE_RECEIVED,
    mobilite: mobilite
});

export const loadAgenda = () => ({
    type: LOAD_AGENDA
});

export const agendaReceived = (agenda) => ({
    type: AGENDA_RECEIVED,
    agenda: agenda
});

export const fetchError = () => ({
    type: SET_ERROR
});

export const setSelectedEvent = (event) => ({
    type: SET_SELECTED_EVENT,
    poi: event
});
