import React from 'react';
import styled from 'styled-components';
import images from '../config/images';

const SwiperWrapper = styled.div`
    display: block;
    pointer-events: none;
`;

const SwipeIconH = styled.img`
    display: block;
    width: ${props => props.theme.scale * 195}px;
`;

const SwipeIconV = styled.img`
    display: block;
    width: ${props => props.theme.scale * 90}px;
`;

const SwipeIndicator = ({ className, direction }) => {
    let icon = images.icons.swipe_h;
    if (direction === 'v') icon = images.icons.swipe_v;

    return(
        <SwiperWrapper className={className}>
            {direction === 'v' ? 
                <SwipeIconV src={icon} />
            :
                <SwipeIconH src={icon} />
            }
        </SwiperWrapper>
    );
}

export default SwipeIndicator;
