import React from 'react';
import styled, { keyframes } from 'styled-components';

/**
 * Grid
 */

const GridWrapper = styled.div`
    max-height: 100%;
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    flex-wrap: wrap;
`;

const Grid = ({ children }) => {
    return (
        <GridWrapper>
            {children}
        </GridWrapper>
    );
}

/**
 * Loading
 */

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const LoadingWrapper = styled.div`
    position: absolute;
    top: 20%;
    left: calc(50% - ${props => props.theme.scale * 32}px);
    display: inline-block;
    width: ${props => props.theme.scale * 64}px;
    height: ${props => props.theme.scale * 64}px;

    &:after {
        content: " ";
        display: block;
        width: ${props => props.theme.scale * 46}px;
        height: ${props => props.theme.scale * 46}px;
        margin: ${props => props.theme.scale * 1}px;
        border-radius: 50%;
        border: ${props => props.theme.scale * 5}px solid ${props => props.theme.shade5};
        border-color: ${props => props.theme.shade5} transparent ${props => props.theme.shade5} transparent;
        animation: ${rotate} 1.2s linear infinite;
    }
`;


const Loading = () => {
    return (
        <LoadingWrapper />
    );
}

export {
    Grid,
    Loading
};

