const mapStyles = [
    {
    "featureType": "poi",
    "stylers": [
        {
        "visibility": "off"
        }
    ]
    },
    {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
        {
        "visibility": "off"
        }
    ]
    },
    {
    "featureType": "transit",
    "stylers": [
        {
        "visibility": "off"
        }
    ]
    },
    {
    "featureType": "administrative",
    "elementType": "labels",
    "stylers": [
        {
        "visibility": "off"
        }
    ]
    },
];

export default mapStyles;
